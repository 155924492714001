import { AccountMixin } from '../AccountCore'

export interface Extension {
  mayUsePageEditor: boolean
}

export const extension: AccountMixin<Extension> = (_core, { userProfile }) => ({
  get mayUsePageEditor() {
    return !!userProfile.isActiveMemberOfTeam
  },
})
